import React, { useEffect } from "react";
import { StyledContainer, StyledChangepswdBlock } from "./style";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { encryptVal } from "../../../utils/utility";
import { changePassword } from "../../../features/user/authSlice";

const initialValues = {
    newPassword: "",
};

const changePasswordSchema = yup.object({
    newPassword: yup.string().min(6, "New Password must be at least 6 characters.").required("Please enter your New Password."),
});

const ChangePassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Singapore Arrival Card";
    }, []);

    const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: changePasswordSchema,
        onSubmit: (values) => {
            dispatch(changePassword(values.newPassword)).unwrap().then((res) => {
                if (res.status === 1) {
                    toast.success(`${res.message}`, { className: "toast-message" });
                    navigate("/");
                } else if (res.status === 401) {
                    toast.error(`${res?.message}`, { className: "toast-message" });
                    localStorage.removeItem("user");
                    navigate("/");
                } else {
                    toast.error(`${res.message}`, { className: "toast-message" });
                }
            });
        },
    });
    return (
        <StyledContainer>
            <div className="row">
                <div className="col-md-12">
                    <StyledChangepswdBlock>
                        <h2>Update Password</h2>
                        <div className="table-block table-responsive">
                            <form onSubmit={handleSubmit}>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "30%" }}>Enter New Password</td>
                                            <td style={{ width: "70%" }}>
                                                <input
                                                    type="password"
                                                    id="newPassword"
                                                    name="newPassword"
                                                    className="form-control"
                                                    placeholder="Enter your password"
                                                    autoComplete="off"
                                                    value={values.newPassword}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <p>{errors.newPassword}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><button type="submit" className="btn" style={{ background: "#484242", color: "#fff" }}>Update Password</button></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </StyledChangepswdBlock>
                </div>
            </div>
        </StyledContainer>
    );
};

export default ChangePassword;
