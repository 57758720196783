import React, { useEffect, useState } from "react";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";
import ReactDataTable from "../../../components/ReactDataTable/ReactDataTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersList } from "../../../features/order/orderSlice";
import { toast } from "react-toastify";

const ContactCustomer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const contactCustomersList = useSelector((state) => state?.order?.ordersList);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        dispatch(
            getOrdersList({
                orderName: "contactCustomerOrder",
                page: page,
                perPage: limit,
            })).unwrap().then((res) => {
                if (res.status === 401) {
                    toast.error(`${res?.message}`, {
                        className: "toast-message",
                    });
                    localStorage.removeItem("user");
                    navigate("/");
                }
            });
        document.title = "Singapore Arrival Card";
    }, [dispatch, navigate, page, limit]);

    const handleClick = () => {
        window.location.reload();
    };
    const tableData = contactCustomersList;
    return (
        <StyledContainer>
            <StyledPageTitle>
                <h1>Contact Customer</h1>
                <button type="button" className="btn" style={{ background: "green", color: "#fff" }} onClick={handleClick}>Refresh</button>

            </StyledPageTitle>
            <form>
                <StyledOrderBlock>
                    <div className="table-block table-responsive">
                        <ReactDataTable
                            data={tableData}
                            setPage={setPage}
                            setLimit={setLimit}
                            orderName="contactcustomerorder"
                        />
                    </div>
                </StyledOrderBlock>
            </form>
        </StyledContainer>
    );
};

export default ContactCustomer;
