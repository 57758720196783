import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { StyledOrderBlock, StyledBottomBlock } from './style'
import rightArrow from '../../assets/images/right-arrow.png'
import { useDispatch, useSelector } from 'react-redux'
import {
  getOrderDetailsByOrderId,
  resendMail,
  uploadVisaDocument
} from '../../features/order/orderSlice'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as yup from 'yup'
import AddComment from './AddComment/AddComment'
import MyModal from '../Modal/Modal'
import EmailContent from './EmailContent/EmailContent'
import ImportingGoodsDetails from './ImportingGoodsDetails'
import DefenceContent from './DefenceContent/DefenceContent'
import TransactionModal from './TransactionModal/TransactionModal'
import { getPhoneCode } from '../../utils/utility'

const ViewOrders = () => {
  const dispatch = useDispatch()
  const { orderId } = useParams()
  const [show, setShow] = useState(false)
  const [showDefencePack, setShowDefencePack] = useState(false)
  const [showDefence, setShowDefence] = useState(false)
  const [showTransaction, setShowTransaction] = useState(false)

  const OrderDetails = useSelector(state => state?.order?.orderDetails)
  // console.log("OrderDetails", OrderDetails);
  const isLoading = useSelector(state => state?.order?.loading)

  const [selectedData, setSelectedData] = useState({
    showData: false,
    dataId: 0
  })

  const [emailId, setEmailId] = useState(OrderDetails?.email)
  const [orderid, setEmailIdOrderid] = useState(OrderDetails?.order_id)
  const [mailServiceProvider, setMailServiceProvider] = useState('AWS')
  const uploadSchema = yup.object({
    email: yup.string().required('Please enter your email'),
    fileUpload: yup.mixed().required('File is required')
  })

  const nationalityArray = {
    USA: "UNITED STATES",
    CAN: "CANADA",
    COL: "COLOMBIA",
    NLD: "NETHERLANDS",
    ARG: "ARGENTINA",
    BRA: "BRAZIL",
    CHL: "CHILE",
    PER: "PERU",
    GBR: "UNITED KINGDOM",
    ECU: "ECUADOR",
    AFG: "AFGHANISTAN",
    ALB: "ALBANIA",
    DZA: "ALGERIA",
    ASM: "AMERICAN SAMOA",
    AND: "ANDORRA",
    AGO: "ANGOLA",
    AIA: "ANGUILLA",
    ATA: "ANTARCTICA",
    ATG: "ANTIGUA AND BARBUDA",
    ARM: "ARMENIA",
    ABW: "ARUBA",
    AUS: "AUSTRALIA",
    AUT: "AUSTRIA",
    AZE: "AZERBAIJAN",
    BHS: "BAHAMAS",
    BHR: "BAHRAIN",
    BGD: "BANGLADESH",
    BRB: "BARBADOS",
    BLR: "BELARUS",
    BEL: "BELGIUM",
    BLZ: "BELIZE",
    BEN: "BENIN",
    BMU: "BERMUDA",
    BTN: "BHUTAN",
    BOL: "BOLIVIA",
    BON: "BONAIRE",
    BIH: "BOSNIA AND HERZEGOVINA",
    BWA: "BOTSWANA",
    BVT: "BOUVET ISLAND",
    IOT: "BRITISH INDIAN OCEAN TERRITORY",
    BRN: "BRUNEI DARUSSALAM",
    BGR: "BULGARIA",
    BFA: "BURKINA FASO",
    BDI: "BURUNDI",
    KHM: "CAMBODIA",
    CMR: "CAMEROON",
    CPV: "CAPE VERDE",
    CYM: "CAYMAN ISLANDS",
    CAF: "CENTRAL AFRICAN REPUBLIC",
    TCD: "CHAD",
    CHN: "CHINA",
    CXR: "CHRISTMAS ISLAND",
    CCK: "COCOS (KEELING) ISLANDS",
    COM: "COMOROS",
    COG: "CONGO (Brazzaville)",
    COD: "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
    COK: "COOK ISLANDS",
    CRI: "COSTA RICA",
    HRV: "CROATIA",
    CUB: "CUBA",
    CUR: "CURACAO",
    CYP: "CYPRUS",
    CZE: "CZECH REPUBLIC",
    DNK: "DENMARK",
    DJI: "DJIBOUTI",
    DMA: "DOMINICA",
    DOM: "DOMINICAN REPUBLIC",
    TMP: "EAST TIMOR",
    EGY: "EGYPT",
    SLV: "EL SALVADOR",
    GNQ: "EQUATORIAL GUINEA",
    ERI: "ERITREA",
    EST: "ESTONIA",
    ETH: "ETHIOPIA",
    FLK: "FALKLAND ISLANDS (MALVINAS)",
    FRO: "FAROE ISLANDS",
    FJI: "FIJI",
    FIN: "FINLAND",
    FRA: "FRANCE",
    GUF: "FRENCH GUIANA",
    PYF: "FRENCH POLYNESIA",
    ATF: "FRENCH SOUTHERN TERRITORIES",
    GAB: "GABON",
    GMB: "GAMBIA",
    GEO: "GEORGIA",
    DEU: "GERMANY",
    GHA: "GHANA",
    GIB: "GIBRALTAR",
    GRC: "GREECE",
    GRL: "GREENLAND",
    GND: "GRENADA",
    GLP: "GUADELOUPE",
    GUM: "GUAM",
    GTM: "GUATEMALA",
    GIN: "GUINEA",
    GNB: "GUINEA-BISSAU",
    GUY: "GUYANA",
    HTI: "HAITI",
    HMD: "HEARD ISLAND AND MCDONALD ISLANDS",
    VAT: "HOLY SEE (VATICAN CITY STATE)",
    HND: "HONDURAS",
    HKG: "HONG KONG",
    HUN: "HUNGARY",
    ISL: "ICELAND",
    IND: "INDIA",
    IDN: "INDONESIA",
    IRN: "IRAN, ISLAMIC REPUBLIC OF",
    IRQ: "IRAQ",
    IRL: "IRELAND",
    ISR: "ISRAEL",
    ITA: "ITALY",
    CIV: "IVORY COAST",
    JAM: "JAMAICA",
    JPN: "JAPAN",
    JOR: "JORDAN",
    KAZ: "KAZAKSTAN",
    KEN: "KENYA",
    KIR: "KIRIBATI",
    PRK: "KOREA north, DEMOCRATIC PEOPLE'S REPUBLIC OF",
    KOR: "KOREA south, REPUBLIC OF",
    KWT: "KUWAIT",
    KGZ: "KYRGYZSTAN",
    LAO: "LAOS PEOPLE'S DEMOCRATIC REPUBLIC",
    LVA: "LATVIA",
    LBN: "LEBANON",
    LSO: "LESOTHO",
    LBR: "LIBERIA",
    LBY: "LIBYAN ARAB JAMAHIRIYA",
    LIE: "LIECHTENSTEIN",
    LTU: "LITHUANIA",
    LUX: "LUXEMBOURG",
    MAC: "MACAU",
    MKD: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
    MDG: "MADAGASCAR",
    MWI: "MALAWI",
    MYS: "MALAYSIA",
    MDV: "MALDIVES",
    MLI: "MALI",
    MLT: "MALTA",
    MHL: "MARSHALL ISLANDS",
    FDF: "MARTINIQUE",
    MTQ: "MARTINIQUE",
    MRT: "MAURITANIA",
    MUS: "MAURITIUS",
    MYT: "MAYOTTE",
    MEX: "MEXICO",
    FSM: "MICRONESIA, FEDERATED STATES OF",
    MDA: "MOLDOVA, REPUBLIC OF",
    MCO: "MONACO",
    MNG: "MONGOLIA",
    MSR: "MONTSERRAT",
    MAR: "MOROCCO",
    MOZ: "MOZAMBIQUE",
    MMR: "MYANMAR",
    NAM: "NAMIBIA",
    NRU: "NAURU",
    NPL: "NEPAL",
    ANT: "NETHERLANDS ANTILLES",
    NCL: "NEW CALEDONIA",
    NZL: "NEW ZEALAND",
    NIC: "NICARAGUA",
    NER: "NIGER",
    NGA: "NIGERIA",
    NIU: "NIUE",
    NFK: "NORFOLK ISLAND",
    MNP: "NORTHERN MARIANA ISLANDS",
    NOR: "NORWAY",
    OMN: "OMAN",
    PAK: "PAKISTAN",
    PLW: "PALAU",
    PSE: "PALESTINIAN TERRITORY, OCCUPIED",
    PAN: "PANAMA",
    PNG: "PAPUA NEW GUINEA",
    PRY: "PARAGUAY",
    PHL: "PHILIPPINES",
    PCN: "PITCAIRN",
    POL: "POLAND",
    p: "port everglades",
    PRT: "PORTUGAL",
    PRI: "PUERTO RICO",
    QAT: "QATAR",
    KOS: "REPUBLIC OF KOSOVO",
    MNE: "REPUBLIC OF MONTENEGRO",
    SRB: "REPUBLIC OF SERBIA",
    REU: "REUNION",
    ROM: "ROMANIA",
    RUS: "RUSSIAN FEDERATION",
    RWA: "RWANDA",
    SAB: "SABA",
    SBH: "SAINT BARTS",
    SHN: "SAINT HELENA",
    KNA: "SAINT KITTS AND NEVIS",
    LCA: "SAINT LUCIA",
    SPM: "SAINT PIERRE AND MIQUELON",
    VCT: "SAINT VINCENT AND THE GRENADINES",
    WSM: "SAMOA",
    SMR: "SAN MARINO",
    STP: "SAO TOME AND PRINCIPE",
    SAU: "SAUDI ARABIA",
    SEN: "SENEGAL",
    SYC: "SEYCHELLES",
    SLE: "SIERRA LEONE",
    SGP: "SINGAPORE",
    STA: "SINT EUSTATIUS",
    SXM: "SINT MAARTEN",
    SVK: "SLOVAKIA",
    SVN: "SLOVENIA",
    SLB: "SOLOMON ISLANDS",
    SOM: "SOMALIA",
    ZAF: "SOUTH AFRICA",
    SGS: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
    ESP: "SPAIN",
    LKA: "SRI LANKA",
    SDN: "SUDAN",
    SUR: "SURINAME",
    SJM: "SVALBARD AND JAN MAYEN",
    SWZ: "SWAZILAND",
    SWE: "SWEDEN",
    CHE: "SWITZERLAND",
    SYR: "SYRIAN ARAB REPUBLIC",
    TWN: "TAIWAN, PROVINCE OF CHINA",
    TJK: "TAJIKISTAN",
    TZA: "TANZANIA, UNITED REPUBLIC OF",
    THA: "THAILAND",
    TGO: "TOGO",
    TKL: "TOKELAU",
    TON: "TONGA",
    EIS: "TORTOLA",
    TTO: "TRINIDAD AND TOBAGO",
    TUN: "TUNISIA",
    TUR: "TURKEY",
    TKM: "TURKMENISTAN",
    TCA: "TURKS AND CAICOS ISLANDS",
    TUV: "TUVALU",
    UGA: "UGANDA",
    UKR: "UKRAINE",
    ARE: "UNITED ARAB EMIRATES",
    UMI: "UNITED STATES MINOR OUTLYING ISLANDS",
    UNK: "UNKNOWN",
    URY: "URUGUAY",
    UZB: "UZBEKISTAN",
    VUT: "VANUATU",
    VEN: "VENEZUELA",
    VNM: "VIET NAM",
    VGB: "VIRGIN ISLANDS, BRITISH",
    VIR: "VIRGIN ISLANDS, U.S.",
    WLF: "WALLIS AND FUTUNA",
    ESH: "WESTERN SAHARA",
    YEM: "YEMEN",
    YUG: "YUGOSLAVIA",
    ZMB: "ZAMBIA",
    ZWE: "ZIMBABWE",
  }

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetailsByOrderId(orderId))
    }
    // console.log("orderdetails", OrderDetails);
    document.title = 'Singapore Arrival Card'
  }, [dispatch, orderId])

  useEffect(() => {
    if (
      (OrderDetails &&
        typeof OrderDetails !== 'undefined' &&
        OrderDetails?.email &&
        typeof emailId === 'undefined') ||
      orderId !== orderid
    ) {
      setEmailId(OrderDetails?.email)
    }
  }, [OrderDetails, orderid])

  let defaultInitialValues = {
    orderId: OrderDetails?.order_id,
    email: '',
    fileUpload: ''
  }

  if (orderId !== null && orderId !== undefined && OrderDetails !== null) {
    defaultInitialValues = {
      orderId: OrderDetails?.order_id,
      email: OrderDetails?.email,
      fileUpload: ''
    }
  }

  let [initialValues, setInitialValues] = useState(defaultInitialValues)

  const {
    values,
    errors,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: orderId !== null ? defaultInitialValues : initialValues,
    enableReinitialize: true,
    validationSchema: uploadSchema,
    onSubmit: values => {
      const data = new FormData()
      data.append('orderId', values.orderId)
      data.append('email', values.email)
      data.append('uploadVisaDoc', values.fileUpload)
      data.append('mailServiceProvider', mailServiceProvider)
      dispatch(uploadVisaDocument(data))
        .unwrap()
        .then(res => {
          resetForm()
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: 'toast-message'
            })
            dispatch(getOrderDetailsByOrderId(orderId))
          }
        })
    }
  })

  const handleResendMail = (e, serviceProvider) => {
    e.preventDefault()
    setMailServiceProvider(serviceProvider)
    const data = {
      orderId: OrderDetails?.order_id,
      email: emailId,
      mailServiceProvider: serviceProvider
    }
    dispatch(resendMail(data))
      .unwrap()
      .then(res => {
        if (res.status === 1) {
          toast.success(`${res.message}`, {
            className: 'toast-message'
          })
          dispatch(getOrderDetailsByOrderId(orderId))
        }
      })
  }
  // console.log("OrderDetails", OrderDetails);
  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          {OrderDetails?.mailHistory?.length !== 0 && (
            <StyledOrderBlock>
              <h2>Email History</h2>
              <div className='table-block table-responsive'>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '5%' }}>#</th>
                      <th style={{ width: '10%' }}>Order ID</th>
                      <th style={{ width: '30%' }}>Download</th>
                      <th style={{ width: '25%' }}>Date & Time</th>
                      <th style={{ width: '30%' }}>Mail Provider</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderDetails?.mailHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className='font-normal'>{item?.order_id}</td>
                          {OrderDetails?.uploadDoc?.length ? (
                            <td>
                              <a
                                href={
                                  OrderDetails?.length !== 0
                                    ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH
                                    }docs/${item?.order_id}_${item?.uploadDoc == null
                                      ? OrderDetails?.uploadDoc[0]?.file1
                                      : item?.uploadDoc.file1
                                    }`
                                    : null
                                }
                                className='blue-btn'
                                download
                                target='__blank'
                                rel='noreferrer'
                              >
                                Download File{' '}
                              </a>
                            </td>
                          ) : (
                            ''
                          )}
                          <td>
                            {moment(item?.create_ts)
                              .format('DD/MMMM/YYYY h:mm:ss')}
                          </td>
                          <td>{item?.uploadDoc?.mail_service_provider}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <form className='form-inline'>
                  <div className='form-group'>
                    <label>
                      <input
                        type='email'
                        name='email'
                        id='email'
                        className='form-control input-class'
                        value={emailId}
                        onChange={e => setEmailId(e.target.value)}
                      />
                    </label>
                  </div>
                  <div className='form-group'>
                    <button
                      type='button'
                      className='blue-btn'
                      id='download_btn'
                      disabled={isLoading}
                      onClick={e => {
                        handleResendMail(e, 'AWS')
                      }}
                    >
                      Resend Mail(AWS){' '}
                      <span>
                        <img src={rightArrow} alt='rightArrow' />
                      </span>
                    </button>

                    <button
                      type='button'
                      className='blue-btn'
                      id='download_btn_sendgrid'
                      disabled={isLoading}
                      onClick={e => {
                        handleResendMail(e, 'Sendgrid')
                      }}
                    >
                      Resend Mail(Sendgrid){' '}
                      <span>
                        <img src={rightArrow} alt='rightArrow' />
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </StyledOrderBlock>
          )}
        </div>
        <div className='col-md-12'>
          <StyledOrderBlock>
            <h2>Upload Document</h2>
            <div className='table-block table-responsive fw-mobileview'>
              <form onSubmit={handleSubmit}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: '15%' }}>Order ID</td>
                      <td style={{ width: '35%' }} className='font-normal'>
                        <input
                          type='text'
                          name='orderId'
                          className='form-control'
                          placeholder='Order ID'
                          disabled
                          value={values?.orderId}
                        />
                      </td>
                      <td style={{ width: '15%' }}>Email ID</td>
                      <td style={{ width: '35%' }}>
                        <input
                          type='email'
                          name='email'
                          className='form-control'
                          value={values?.email}
                          onChange={handleChange}
                          placeholder='Email'
                        />
                        <p>{errors.email}</p>
                      </td>
                    </tr>

                    <tr>
                      <td>PDF</td>
                      <tr>
                        <td>
                          <label className='file-upload'>
                            <input
                              type='file'
                              className=''
                              id='fileUpload'
                              name='fileUpload'
                              accept='.pdf,image/*'
                              onChange={e =>
                                setFieldValue(
                                  'fileUpload',
                                  e.currentTarget.files[0]
                                )
                              }
                              onBlur={handleBlur}
                            />
                            <span>Choose File</span>
                          </label>
                          <span className='slcted-file'></span>
                          <p>{errors.fileUpload}</p>
                        </td>
                      </tr>
                    </tr>
                    {OrderDetails?.uploadDoc?.length ? (
                      <td colSpan='2'>
                        <a
                          href={
                            OrderDetails?.length !== 0
                              ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.uploadDoc[0]?.file1}`
                              : null
                          }
                          className='blue-btn'
                          download
                          rel='noreferrer'
                        >
                          Download File{' '}
                        </a>
                      </td>
                    ) : (
                      ''
                    )}
                  </tbody>
                </table>
                <br />
                <button
                  type='submit'
                  className='blue-btn'
                  id='submit_btn'
                  disabled={isLoading}
                  onClick={() => setMailServiceProvider('AWS')}
                >
                  Submit (AWS){' '}
                  <span>
                    <img src={rightArrow} alt='rightArrow' />
                  </span>
                </button>
                <button
                  type='submit'
                  className='blue-btn'
                  id='submit_btn_sendgrid'
                  disabled={isLoading}
                  onClick={() => setMailServiceProvider('Sendgrid')}
                >
                  Submit (SendGrid){' '}
                  <span>
                    <img src={rightArrow} alt='rightArrow' />
                  </span>
                </button>
              </form>
            </div>
          </StyledOrderBlock>
        </div>
        <div className='col-md-12'>
          <StyledOrderBlock>
            <h2>Order Details</h2>
            <div className='table-block table-responsive'>
              <table>
                <tbody>
                  {OrderDetails?.order_id && (
                    <tr>
                      <td>Order ID:</td>
                      <td>{OrderDetails?.order_id}</td>
                    </tr>
                  )}
                  {OrderDetails?.processing_mode && (
                    <tr>
                      <td>Processing Mode</td>
                      <td>{OrderDetails?.processing_mode}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>Personal Information</h2>
            <div className='table-block table-responsive'>
              <table>
                <tbody>
                  {OrderDetails?.arrival_date && (
                    <tr>
                      <td>Arrival Date</td>
                      <td>
                        {OrderDetails?.arrival_date
                          ? moment(OrderDetails?.arrival_date)
                            .format('DD/MMMM/YYYY')
                          : ''}
                      </td>
                    </tr>
                  )}
                  {OrderDetails?.departure_date && (
                    <tr>
                      <td>Date Of Departure</td>
                      <td>
                        {OrderDetails?.departure_date
                          ? moment(OrderDetails?.departure_date)
                            .format('DD/MMMM/YYYY')
                          : ''}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.full_name && (
                    <tr>
                      <td>Full Name</td>
                      <td>{OrderDetails?.full_name}</td>
                    </tr>
                  )}

                  {OrderDetails?.passport_expiry_date && (
                    <tr>
                      <td>Date Of Passport Expiry</td>
                      <td>
                        {OrderDetails?.passport_expiry_date
                          ? moment(OrderDetails?.passport_expiry_date)
                            .format('DD/MMMM/YYYY')
                          : ''}
                      </td>
                    </tr>
                  )}
                  {OrderDetails?.passport_number && (
                    <tr>
                      <td>Passport Number</td>
                      <td>{OrderDetails?.passport_number}</td>
                    </tr>
                  )}

                  {OrderDetails?.date_of_birth && (
                    <tr>
                      <td>Date Of Birth</td>
                      <td>
                        {OrderDetails?.date_of_birth
                          ? moment(OrderDetails?.date_of_birth)
                            .format('DD/MMMM/YYYY')
                          : ''}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.place_of_residence && (
                    <tr>
                      <td>Place of Residence</td>
                      <td>{OrderDetails?.place_of_residence}</td>
                    </tr>
                  )}

                  {OrderDetails?.place_of_birth && (
                    <tr>
                      <td>Place of Birth</td>
                      <td>{OrderDetails?.place_of_birth}</td>
                    </tr>
                  )}

                  {OrderDetails?.sex && (
                    <tr>
                      <td>Sex</td>
                      <td>{OrderDetails?.sex}</td>
                    </tr>
                  )}

                  {OrderDetails?.nationality && (
                    <tr>
                      <td>Nationality/Citizenship</td>
                      <td>{OrderDetails?.nationality}</td>
                    </tr>
                  )}

                  {OrderDetails?.zip_code && (
                    <tr>
                      <td>Billing Zip/Postal Code</td>
                      <td>{OrderDetails?.zip_code}</td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>
          </StyledOrderBlock>


          <StyledOrderBlock>
            <h2>Contact Information</h2>
            <div className='table-block table-responsive'>
              <table>
                <tbody>


                  {OrderDetails?.email && (
                    <tr>
                      <td>Email</td>
                      <td>{OrderDetails?.email.toUpperCase()}</td>
                    </tr>
                  )}
                  {OrderDetails?.confirm_email && (
                    <tr>
                      <td>Re Enter Email</td>
                      <td>{OrderDetails?.confirm_email.toUpperCase()}</td>
                    </tr>
                  )}
                  {OrderDetails?.country_code && OrderDetails?.phone && (
                    <tr>

                      <td>Phone Number</td>
                      <td>{`+ ${getPhoneCode(OrderDetails?.country_code)} ${OrderDetails?.phone}`}</td>
                    </tr>
                  )}
                  {OrderDetails?.used_passport && (
                    <tr>
                      <td>Have you ever used a passport under different name to enter Singapore?</td>
                      <td>{OrderDetails?.used_passport}</td>
                    </tr>
                  )}

                  {OrderDetails?.different_name && OrderDetails?.used_passport == 'Yes' && (
                    <tr>
                      <td>Name different from current passport</td>
                      <td>{OrderDetails?.different_name}</td>
                    </tr>
                  )}

                  {OrderDetails?.current_symptoms && (
                    <tr>
                      <td>Do you currently have fever, cough, shortness of breath, headache or vomiting?</td>
                      <td>{OrderDetails?.current_symptoms}</td>
                    </tr>
                  )}

                  {OrderDetails?.previous_visit && OrderDetails?.current_symptoms == 'No' && (
                    <tr>
                      <td>Have you visited any of the listed countries in Africa or Latin America in the past 6 days prior to your arrival in Singapore?</td>
                      <td>{OrderDetails?.previous_visit}</td>
                    </tr>
                  )}

                  {OrderDetails?.visted_country_list && OrderDetails?.current_symptoms == 'Yes' && (
                    <tr>
                      <td>Have you visited the Middle East, Africa or the countries in Latin America below* in the past 14 days prior to your arrival in Singapore? <br />
                        *Argentina, Bolivia, Brazil, Colombia, Ecuador, French Guiana, Guyana, Panama, Paraguay, Peru, Suriname, Trinidad and Tobago, Venezuela</td>
                      <td>{OrderDetails?.visted_country_list}</td>
                    </tr>
                  )}

                  {OrderDetails?.currently_have_rash && (
                    <tr>
                      <td>Do you currently have rash?</td>
                      <td>{OrderDetails?.currently_have_rash}</td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          <StyledOrderBlock>
            <h2>Trip Information</h2>
            <div className='table-block table-responsive'>
              <table>
                <tbody>

                  {OrderDetails?.last_city && (
                    <tr>
                      <td>Last City / Port of Embarkation Before Singapore</td>
                      <td>{OrderDetails?.last_city}</td>
                    </tr>
                  )}

                  {OrderDetails?.purpose_of_travel && (
                    <tr>
                      <td>Purpose of Travel</td>
                      <td>{OrderDetails?.purpose_of_travel}</td>
                    </tr>
                  )}
                  {OrderDetails?.purpose_of_travel_others && OrderDetails?.purpose_of_travel == 'Others' && (
                    <tr>
                      <td>Purpose of Travel (others)</td>
                      <td>{OrderDetails?.purpose_of_travel_others}</td>
                    </tr>
                  )}

                  {OrderDetails?.travel_mode && (
                    <tr>
                      <td>Mode Of Travel</td>
                      <td>{OrderDetails?.travel_mode}</td>
                    </tr>
                  )}

                  {OrderDetails?.travel_mode_details && OrderDetails?.travel_mode == 'Air' && (
                    <tr>
                      <td>Mode of Travel (Details)</td>
                      <td>{OrderDetails?.travel_mode_details}</td>
                    </tr>
                  )}
                  {OrderDetails?.flight_code && OrderDetails?.travel_mode_details == 'COMMERCIAL FLIGHT' && (
                    <tr>
                      <td>Flight Code</td>
                      <td>{OrderDetails?.flight_code}</td>
                    </tr>
                  )}
                  {OrderDetails?.flight_number && OrderDetails?.travel_mode_details == 'COMMERCIAL FLIGHT' && (
                    <tr>
                      <td>Flight Number</td>
                      <td>{OrderDetails?.flight_number}</td>
                    </tr>
                  )}

                  {OrderDetails?.travel_mode_details_land && OrderDetails?.travel_mode == 'Land' && (
                    <tr>
                      <td>Mode of Travel (LAND Details)</td>
                      <td>{OrderDetails?.travel_mode_details_land}</td>
                    </tr>
                  )}
                  {OrderDetails?.vecle_number && OrderDetails?.travel_mode == 'Land' && (
                    <tr>
                      <td>Vehicle Number</td>
                      <td>{OrderDetails?.vecle_number}</td>
                    </tr>
                  )}
                  {(
                    <tr>
                      <td>Type of Accommodation in Singapore</td>
                    </tr>
                  )}

                  {OrderDetails?.accommodation && (
                    <tr>
                      <td>Type of Accommodation</td>
                      <td>{OrderDetails?.accommodation}</td>
                    </tr>
                  )}
                  {OrderDetails?.hotel_name && OrderDetails?.accommodation == 'Hotel' && (
                    <tr>
                      <td>Name of Hotel</td>
                      <td>{OrderDetails?.hotel_name}</td>
                    </tr>
                  )}
                  {OrderDetails?.street_name && OrderDetails?.accommodation == 'Residential' && (
                    <tr>
                      <td>Name of Hotel</td>
                      <td>{OrderDetails?.street_name}</td>
                    </tr>
                  )}
                  {OrderDetails?.building_name && OrderDetails?.accommodation == 'Residential' && (
                    <tr>
                      <td>Building Name</td>
                      <td>{OrderDetails?.building_name}</td>
                    </tr>
                  )}
                  {OrderDetails?.postal_code && OrderDetails?.accommodation == 'Residential' && (
                    <tr>
                      <td>Postal Code</td>
                      <td>{OrderDetails?.postal_code}</td>
                    </tr>
                  )}
                  {OrderDetails?.accomodation_other && OrderDetails?.accommodation == 'Others' && (
                    <tr>
                      <td>Accommodation (Others)</td>
                      <td>{OrderDetails?.accomodation_other}</td>
                    </tr>
                  )}

                  {OrderDetails?.next_city && (
                    <tr>
                      <td>Next City / Port of Disembarkation After Singapore</td>
                      <td>{OrderDetails?.next_city}</td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>
          </StyledOrderBlock>




          <StyledOrderBlock>
            <h2>Download History</h2>
            <div className='table-block table-responsive'>
              <table width='100%'>
                <thead>
                  <tr>
                    <th width='5%'>#</th>
                    <th width='10%'>Order ID</th>
                    <th width='22%'>Date & Time</th>
                    <th width='23%'>Downloaded File</th>
                    <th width='20%'>IP Address</th>
                    <th width='20%'>Location</th>
                  </tr>
                </thead>
                <tbody>
                  {OrderDetails?.downloadHistory ? (
                    OrderDetails?.downloadHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            {moment(item?.create_ts)
                              .format('DD/MMMM/YYYY h:mm:ss')}{' '}
                            EST
                          </td>
                          <td>
                            {OrderDetails?.uploadDoc?.length !== 0 && (
                              <a
                                href={
                                  OrderDetails?.uploadDoc !== undefined
                                    ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${item?.order_id}_${OrderDetails?.uploadDoc[0]?.file1}`
                                    : null
                                }
                                className='blue-btn'
                                target='_blank'
                                download
                                rel='noreferrer'
                              >
                                Download File
                              </a>
                            )}
                          </td>
                          <td>{item?.ip}</td>
                          <td>{item?.timezone}</td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan='4' align='center'>
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <form className='form-inline'>
                <div className='form-group'>
                  <label>
                    <input
                      type='email'
                      name='email'
                      id='email'
                      className='form-control input-class'
                      value={emailId}
                      onChange={e => setEmailId(e.target.value)}
                    />
                  </label>
                </div>
                <div className='form-group'>
                  <button
                    type='submit'
                    className='blue-btn'
                    id='download_btn'
                    onClick={handleResendMail}
                    disabled={isLoading}
                  >
                    Sent Mail{' '}
                    <span>
                      <img src={rightArrow} alt='rightArrow' />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </StyledOrderBlock>
          <AddComment />
          <StyledOrderBlock>
            <h2>Comment History</h2>
            <div className='table-block table-responsive'>
              <table>
                <thead>
                  <tr>
                    <th width='10%'>#</th>
                    <th width='20%'>Order ID</th>
                    <th width='20%'>Date & Time</th>
                    <th width='15%'>Team</th>
                    <th width='35%'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {typeof OrderDetails !== 'undefined' &&
                    OrderDetails.remark ? (
                    OrderDetails.remark?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            {moment(item?.us_date).utc().format('DD/MMMM/YYYY')}
                            {'  '}
                            {item?.us_time}
                          </td>
                          <td>{item?.remark_name}</td>
                          <td>
                            {selectedData.showData &&
                              selectedData.dataId === item.id ? (
                              <div
                                rel='40'
                                className='btn blue-btn'
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id
                                  })
                                }
                              >
                                {' '}
                                Hide
                              </div>
                            ) : (
                              <div
                                rel='40'
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id
                                  })
                                }
                                className='btn blue-btn'
                              >
                                {' '}
                                Show
                              </div>
                            )}
                            <br />
                            {selectedData.showData &&
                              selectedData.dataId === item.id && (
                                <div id='40'>
                                  <textarea
                                    name='remark'
                                    className='form-control'
                                    defaultValue={item?.remark}
                                  ></textarea>
                                  <b>
                                    Whatsapp:
                                    {item?.whatsapp ? item?.whatsapp : 'N'}
                                  </b>
                                  <b>
                                    Telephone:
                                    {item?.telephone ? item?.telephone : 'N'}
                                  </b>
                                  <b>
                                    Refund:
                                    {item?.status_refund
                                      ? item?.status_refund
                                      : 'N'}
                                  </b>
                                  <b>
                                    Voided:
                                    {item?.voided ? item?.voided : 'N'}
                                  </b>
                                  <b>
                                    Chargeback:
                                    {item?.chargeback ? item?.chargeback : 'N'}
                                  </b>
                                  <b>
                                    ReportError:
                                    {item?.report_error
                                      ? item?.report_error
                                      : 'N'}
                                  </b>
                                </div>
                              )}
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan='5' align='center'>
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
        </div>

        <StyledBottomBlock>
          <button
            type='button'
            className='blue-btn'
            data-toggle='modal'
            data-target='#refundModal'
            onClick={() => setShow(true)}
          >
            View Email Content{' '}
          </button>

          {/* defence pack */}
          {(OrderDetails?.process_status == 'Completed' ||
            OrderDetails?.process_status == 'Customer Called') && (
              <>
                <button
                  type='button'
                  className='blue-btn'
                  data-toggle='modal'
                  data-target='#defencePackModal'
                  onClick={() => setShowDefence(true)}
                >
                  Defence Pack 1{' '}
                  <span>
                    <img src={rightArrow} alt='rightArrow' />
                  </span>
                </button>
                <button
                  type='button'
                  className='blue-btn'
                  data-toggle='modal'
                  data-target='#defencePackModal1'
                  onClick={() => setShowDefencePack(true)}
                >
                  Defence Pack 2{' '}
                  <span>
                    <img src={rightArrow} alt='rightArrow' />
                  </span>
                </button>

                <button
                  type='button'
                  className='blue-btn'
                  data-toggle='modal'
                  data-target='#defencePackModal'
                  onClick={() => setShowTransaction(true)}
                >
                  Enter Transaction Details{' '}
                </button>
              </>
            )}

          <MyModal
            show={show}
            close={setShow}
            size={'lg'}
            modalHeadTitle={'Email Content'}
            showFooter={true}
            primaryButtonName={'Close'}
            secondaryButtonName={'Send Whatsapp Message'}
            OrderDetails={OrderDetails}
          >
            <EmailContent />
          </MyModal>

          <MyModal
            show={showDefence}
            close={setShowDefence}
            size={'lg'}
            modalHeadTitle={'Defence Pack Content'}
            showFooter={false}
            OrderDetails={OrderDetails}
          >
            <DefenceContent defencePackName={null} />
          </MyModal>
          <MyModal
            show={showDefencePack}
            close={setShowDefencePack}
            size={'lg'}
            modalHeadTitle={'Defence Pack Content'}
            showFooter={false}
            OrderDetails={OrderDetails}
          >
            <DefenceContent defencePackName={'defencePack1'} />
          </MyModal>

          <MyModal
            show={showTransaction}
            close={setShowTransaction}
            size={'s'}
            modalHeadTitle={'Enter Defence Pack Details:'}
            showFooter={false}
            OrderDetails={OrderDetails?.length > 0 ? OrderDetails[0] : ''}
          >
            <TransactionModal setShowTransaction={setShowTransaction} />
          </MyModal>
        </StyledBottomBlock>
      </div>
    </>
  )
}

export default ViewOrders
