import React from "react";
import AdminLogo from "../../../assets/images/logo.jpg";
import User from "../../../assets/images/user.png";
import { FaSignOutAlt } from "react-icons/fa";
import { StyledHeader, StyledHeaderRight, StyledLogo, StyledAdminHeaderRight, StyleLogoText } from "./style";
import Dropdown from 'react-bootstrap/Dropdown';
// import { toggleSidebar } from "../../../features/order/orderSlice";
import { logout } from "../../../features/user/authSlice";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode"
import { useNavigate } from "react-router-dom";

const AdminNavbar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = localStorage.getItem("user");
    const handleLogout = (e) => {
        e.preventDefault();
        localStorage.removeItem("user");
        dispatch(logout());
        navigate('/');
    };

    let userDetails;

    if (token !== null) {
        const decodedToken = jwtDecode(token);
        userDetails = decodedToken?.user;
    }

    return (
        <StyledHeader>
            <div className="toggle-button-box">
                <button
                    type="button"
                    className="toggle-button"
                // onClick={() => {
                //     dispatch(toggleSidebar());
                // }}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>
            <StyledLogo>
                <img src={AdminLogo} alt="Logo" height="70" width="140" />
                <StyleLogoText>
                    <h2 className="logoTextUK">Singapore</h2>
                    <h2 className="logoTextETA">Arrival Card</h2>
                </StyleLogoText>
            </StyledLogo>
            <StyledHeaderRight>
                <StyledAdminHeaderRight>
                    <ul>
                        <li className="dropdown">
                            <span className="admin-name">{`Welcome ${userDetails?.name}`}</span>
                            <div className="user-top">
                                <span className="online"></span>
                                <span className="user-img">
                                    <img src={User} alt="user" />
                                </span>
                            </div>
                            <Dropdown>
                                <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="dropdown-toggle"
                                >
                                    {" "}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#" onClick={handleLogout}>
                                        {" "}
                                        <FaSignOutAlt className="logout-icons" />
                                        Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                    </ul>
                </StyledAdminHeaderRight>
            </StyledHeaderRight>
        </StyledHeader>
    );
};

export default AdminNavbar;
