import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { StyledTrack } from "./style";
import { StyledError } from "./style";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { trackCustomerOrder } from "../../features/visitor/visitorSlice";
import { decryptVal } from "../../utils/utility";

const TrackOrder = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { orderId } = useParams();

    const initialValues = {
        orderId: decryptVal(orderId),
        email: "",
    };

    const contactUsSchema = yup.object({
        orderId: yup.string().required("Please enter your orderId"),
        email: yup.string().required("Please enter your email"),
    });

    useEffect(() => {
        document.title = "Singapore Arrival Card";
    }, []);

    const { values, errors, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
        initialValues: initialValues,
        validationSchema: contactUsSchema,
        onSubmit: (values) => {
            dispatch(trackCustomerOrder(values)).unwrap().then((res) => {
                console.log('track order response ', res);
                if (res.status === 1) {
                    resetForm();
                    toast.success(`${res.message}`, { className: "toast-message" });
                    navigate(`/get-new-order-details/${orderId}`);
                } else {
                    toast.error(`${res.message}`, { className: "toast-message" });
                }
            });
        },
    });

    return (
        <StyledTrack>
            <div className="container mt-5">
                <h3>
                    To Download your Embarkation Disembarkation Card, please enter the email used on your application.
                </h3>
                <hr />
                <form onSubmit={handleSubmit}>
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Order ID:</label>
                                    <input
                                        type="text"
                                        className="form-control py-3"
                                        id="exampleFormControlInput1"
                                        placeholder=""
                                        name="orderId"
                                        value={initialValues.orderId}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <StyledError>{errors.name}</StyledError>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label" >Email ID:</label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="form-control py-3"
                                        id="exampleFormControlInput1"
                                        placeholder="Your Email"
                                    />
                                    <StyledError>{errors.email}</StyledError>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn-success btn-lg">TRACK ORDER</button>
                </form>
            </div>

        </StyledTrack>
    );
};

export default TrackOrder;
